import { init } from '@sentry/browser';
import $ from 'jquery';
import { Button, Carousel, Modal, Tooltip } from 'bootstrap';
import * as htmx from 'htmx.org';
import 'bootstrap-table/dist/bootstrap-table.js';
import 'bootstrap-table/dist/extensions/fixed-columns/bootstrap-table-fixed-columns.js';
import { init } from '@sentry/browser';

import {
    Chart,
    PointElement,
    LineElement,
    Tooltip as ChartTooltip,
    LineController,
    CategoryScale,
    LinearScale,
    Legend
} from 'chart.js';

Chart.register(
    PointElement,
    LineElement,
    ChartTooltip,
    LineController,
    CategoryScale,
    LinearScale,
    Legend
);

window.$ = $;
window.jQuery = $;
window.bootstrap = { Button, Carousel, Modal, Tooltip };
window.htmx = htmx;
window.Chart = Chart;
window.Sentry = { init };
